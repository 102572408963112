import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { User, UserRole } from '../../pages/customers/customers.type';

@Injectable()
export class CanActivateForEntityGuard implements CanActivate, CanLoad {
  constructor(private authService: NbAuthService, private router: Router) {}

  process() {
    return this.authService.isAuthenticated().pipe(
      tap((authenticated) => {
        if (!authenticated) return false;

        const user =
          localStorage.getItem('user') && (JSON.parse(localStorage.getItem('user')) as User);
        if (user?.role !== UserRole.ENTITY_OWNER) return false;

        return true;
      })
    );
  }

  canLoad() {
    return this.process();
  }

  canActivate() {
    return this.process();
  }
}
