import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbTokenLocalStorage } from '@nebular/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private nbTokenLocalStorage: NbTokenLocalStorage) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenObj = this.nbTokenLocalStorage.get();

    const isRefreshTokenEndpoint = httpRequest.url.includes('/users/refreshToken');
    let token = tokenObj?.getValue();

    if (isRefreshTokenEndpoint) {
      const user = localStorage.getItem('user');
      if (user) {
        token = JSON.parse(user)?.refreshToken;
      }
    }

    return next.handle(httpRequest.clone({ setHeaders: { Authorization: token } }));
  }
}
