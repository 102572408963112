import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpResponse,
  HttpClient
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiResponseType } from '../../shared/typings/api-response.type';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private apiUrl: string = `${environment.apiBase}`;

  constructor(
    private toastrService: NbToastrService,
    private router: Router,
    private http: HttpClient
  ) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpMethod = httpRequest.method,
      isRefreshTokenEndpoint = httpRequest.url.includes('/users/refreshToken');

    return next.handle(httpRequest).pipe(
      tap((event: HttpResponse<void>) => {
        const isUploadProcess = event.url === `${environment.apiBase}/upload`;
        if (
          !['GET', 'OPTIONS'].includes(httpMethod) &&
          [200, 201].includes(event.status) &&
          !isUploadProcess &&
          !isRefreshTokenEndpoint
        ) {
          this.toastrService.success('Operation done successfully', 'Done!', {
            icon: 'checkmark-outline'
          });
          return EMPTY;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          console.log('----------- ERROR: API CONNECTION -----------', error);
          this.toastrService.danger('Please check your API connection!', 'Error!', {
            icon: 'alert-circle-outline'
          });
          return EMPTY;
        } else if (error.status === 401) {
          return isRefreshTokenEndpoint ? this.logout(error) : this.refreshToken(httpRequest, next);
        } else {
          this.toastrService.danger(error.error.message || 'Something went wrong!', 'Error!', {
            icon: 'alert-circle-outline'
          });
          return throwError(() => error.error);
        }
      })
    );
  }

  private refreshToken(httpRequest: HttpRequest<any>, next: HttpHandler) {
    return this.http.post(`${this.apiUrl}/users/refreshToken`, {}).pipe(
      switchMap((res: ApiResponseType<any>) => {
        if (res.statusCode === 200) {
          localStorage.setItem(
            'auth_app_token',
            JSON.stringify({
              createdAt: Date.now(),
              name: 'nb:auth:simple:token',
              ownerStrategyName: 'email',
              value: res.data.token
            })
          );
        }
        const clonedReq = httpRequest.clone({
          setHeaders: { Authorization: res.data.token }
        });
        return next.handle(clonedReq);
      })
    );
  }

  private logout(error: HttpErrorResponse) {
    localStorage.removeItem('user');
    localStorage.removeItem('auth_app_token');
    this.router.navigateByUrl('/auth/login');
    this.toastrService.danger(error.error?.message, 'Error!', {
      icon: 'alert-circle-outline'
    });
    return EMPTY;
  }
}
