import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { CanActivate, Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class PagesGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {}

  canActivate() {
    return this.authService.isAuthenticated().pipe(
      tap((authenticated) => {
        if (authenticated) return true;

        return this.router.navigate(['/auth/login']);
      })
    );
  }
}
